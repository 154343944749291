import { createRouter, createWebHistory } from 'vue-router'

const router = createRouter({
  mode: 'history',
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      path: '',
      name: 'Root',
      redirect: '/landing',
      component: () => import('../App.vue'),
      children: [
        {
          path: '/landing',
          name: 'Landing',
          meta: {
            liveChat: true,
            needAuth: false
          },
          component: () => import('../views/users/LandingView.vue')
        },
        {
          path: '/panel',
          name: 'user',
          component: () => import('../views/users/UserView.vue'),
          redirect: '/panel/home',
          children: [
            {
              path: '/panel/transfer-proof/:id/:selectEnable',
              name: 'transfer-proof-detail',
              props: true,
              meta: {
                useReturn: true
              },
              component: () => import('../views/users/TransferProofDetail.vue')
            },
            {
              path: '/panel/home',
              name: 'Home',
              meta: {
                liveChat: true,
                liveChatBottom: '75px'
              },
              component: () => import('../views/users/HomeView.vue')
            },
            {
              path: '/panel/positions',
              name: 'Positions',
              component: () => import('../views/users/PositionsView.vue')
            },
            {
              path: '/panel/orders',
              name: 'Orders',
              component: () => import('../views/users/OrdersView.vue')
            },
            {
              path: '/panel/markets',
              name: 'Markets',
              component: () => import('../views/users/MarketsView.vue')
            },
            {
              path: '/panel/reports/:nav',
              name: 'Reports',
              props: true,
              component: () => import('../views/users/ReportsView.vue')
            },
            {
              path: '/panel/profile',
              name: 'Profile',
              meta: {
                liveChat: true,
                liveChatBottom: '75px'
              },
              component: () => import('../views/users/ProfileView.vue')
            },
            {
              path: '/panel/position/:id',
              name: 'position-detail',
              props: true,
              meta: {
                useReturn: true
              },
              component: () => import('../views/users/PositionDetailView.vue')
            },
            {
              path: '/panel/crypto-withdraw-request/:id',
              name: 'crypto-withdraw-request-detail',
              props: true,
              meta: {
                noMenu: true,
                useReturn: true
              },
              component: () => import('../views/users/CryptoWithdrawRequestDetailView.vue')
            },
            {
              path: '/panel/crypto-deposit-request/:id',
              name: 'crypto-deposit-request-detail',
              props: true,
              meta: {
                noMenu: true,
                useReturn: true
              },
              component: () => import('../views/users/CryptoDepositRequestDetailView.vue')
            },
            {
              path: '/panel/cash-deposit-request/:id',
              name: 'cash-deposit-request-detail',
              props: true,
              meta: {
                noMenu: true,
                useReturn: true
              },
              component: () => import('../views/users/CashDepositRequestDetailView.vue')
            },
            {
              path: '/panel/cash-withdraw-request/:id',
              name: 'cash-withdraw-request-detail',
              props: true,
              meta: {
                noMenu: true,
                useReturn: true
              },
              component: () => import('../views/users/CashWithdrawRequestDetailView.vue')
            },
            {
              path: '/panel/bank-withdraw-request/:id',
              name: 'bank-withdraw-request-detail',
              props: true,
              meta: {
                noMenu: true,
                useReturn: true
              },
              component: () => import('../views/users/BankWithdrawRequestDetailView.vue')
            },
            {
              path: '/panel/order/:id',
              name: 'order-detail',
              props: true,
              meta: {
                noMenu: true,
                useReturn: true
              },
              component: () => import('../views/users/OrderDetailView.vue')
            },
            {
              path: '/panel/crypto-transaction-create/:type/:assetSymbol',
              name: 'crypto-transaction-create',
              props: true,
              meta: {
                noMenu: true,
                useReturn: true
              },
              component: () => import('../views/users/CryptoRequestView.vue')
            },
            {
              path: '/panel/cash-transaction/:type/:assetSymbol',
              name: 'cash-transaction',
              props: true,
              meta: {
                noMenu: true,
                useReturn: true
              },
              component: () => import('../views/users/CashRequestView.vue')
            },
            {
              path: '/panel/bank-transaction/:type/:assetSymbol',
              name: 'bank-transaction',
              props: true,
              meta: {
                noMenu: true,
                useReturn: true
              },
              component: () => import('../views/users/BankTransferRequestView.vue')
            },
            {
              path: '/panel/position-create/:marketBaseAssetSymbol-:marketQuoteAssetSymbol/:side',
              name: 'position-create',
              props: true,
              meta: {
                noMenu: true,
                useReturn: true
              },
              component: () => import('../views/users/PositionCreateView.vue')
            },
            {
              path: '/panel/order-create/:marketBaseAssetSymbol-:marketQuoteAssetSymbol/:side',
              name: 'order-create',
              props: true,
              meta: {
                noMenu: true,
                useReturn: true
              },
              component: () => import('../views/users/OrderCreateView.vue')
            },
            {
              path: '/panel/login',
              name: 'Login',
              meta: {
                noMenu: true,
                liveChat: true,
                needAuth: false
              },
              component: () => import('../views/users/LoginView.vue')
            },
            {
              path: '/panel/login/otp/:selectedMethod',
              name: 'Login with OTP',
              props: true,
              meta: {
                noMenu: true,
                liveChat: true,
                needAuth: false
              },
              component: () => import('../views/users/OTPLoginView.vue')
            },
            // {
            //   path: '/register',
            //   name: 'Register',
            //   meta: {
            //     noMenu: true,
            //     needAuth: false,
            //     liveChat: true
            //   },
            //   component: () => import('../views/users/RegisterView.vue')
            // },
            {
              path: '/panel/sign-up/:selectedMethod',
              default: '/sign-up/email',
              name: 'Sign Up',
              props: true,
              meta: {
                noMenu: true,
                needAuth: false,
                liveChat: true
              },
              component: () => import('../views/users/SignUpView.vue')
            },
            {
              path: '/panel/set-password/',
              name: 'Set Password',
              meta: {
                noMenu: true,
                liveChat: true,
                needAuth: true
              },
              component: () => import('../views/users/SignUpSetPasswordView.vue')
            },
            {
              path: '/panel/user-profile/',
              name: 'User Profile',
              meta: {
                noMenu: true,
                liveChat: true,
                needAuth: true
              },
              component: () => import('../views/users/SetUserProfileDetailView.vue')
            },
            {
              path: '/panel/confirm-email/',
              name: 'Confirm Email',
              meta: {
                noMenu: true,
                needAuth: false,
                liveChat: true
              },
              component: () => import('../views/users/ConfirmEmailView.vue')
            },
            {
              path: '/panel/forget-password/:selectedMethod',
              default: '/forget-password/phone-number',
              name: 'Forget Password',
              props: true,
              meta: {
                noMenu: true,
                needAuth: false,
                liveChat: true,
                useReturn: true
              },
              component: () => import('../views/users/ForgetPasswordView.vue')
            },
            {
              path: '/panel/test',
              name: 'Test',
              meta: {
                noMenu: true,
                needAuth: false
              },
              component: () => import('../views/users/TestView.vue')
            },
            {
              path: '/panel/:pathMatch(.*)',
              redirect: (to) => {
                return { path: `/panel/home` }
              }
            }
          ]
        },
        {
          path: '/bot',
          name: 'bot',
          component: () => import('../views/bot/BotView.vue'),
          redirect: '/bot/home',
          children: [
            {
              path: '/bot/home',
              name: 'Bot Home',
              props: true,
              component: () => import('../views/bot/BotView.vue')
            },
            {
              path: '/bot/exchange-market/:botExchangeMarketId/:edit',
              name: 'Admin Bot Exchange Market Detail',
              props: true,
              meta: {
                useReturn: true
              },
              component: () => import('../views/bot/BotExchangeMarketDetailView.vue')
            },
            {
              path: '/bot/orders',
              name: 'Admin Bot Orders',
              component: () => import('../views/bot/BotOrdersView.vue')
            },
            {
              path: '/bot/exchange-markets',
              name: 'Admin Bot Exchange Markets',
              component: () => import('../views/bot/BotExchangeMarketsView.vue')
            },
            {
              path: '/bot/market/:botMarketId/:edit',
              name: 'Admin Bot Market Detail',
              props: true,
              meta: {
                useReturn: true
              },
              component: () => import('../views/bot/BotMarketDetailView.vue')
            },
            {
              path: '/bot/markets',
              name: 'Admin Bot Markets',
              component: () => import('../views/bot/BotMarketsView.vue')
            }
          ]
        },
        {
          path: '/admin',
          name: 'admin',
          component: () => import('../views/admins/AdminView.vue'),
          redirect: '/admin/home/Withdraw/New',
          children: [
            {
              path: '/admin/cash-transaction/:type/:assetSymbol',
              name: 'admin-cash-transaction',
              props: true,
              meta: {
                noMenu: true,
                useReturn: true
              },
              component: () => import('../views/users/CashRequestView.vue')
            },
            {
              path: '/admin/transfer-proof/:id/:selectEnable',
              name: 'admin-transfer-proof-detail',
              props: true,
              meta: {
                useReturn: true
              },
              component: () => import('../views/users/TransferProofDetail.vue')
            },
            {
              path: '/admin/home/:side/:state',
              name: 'Admin Home',
              default: '/admin/home/Withdraw/New',
              props: true,
              meta: {
                useSearch: true
              },
              component: () => import('../views/admins/HomeView.vue')
            },
            {
              path: '/admin/interest-rates/:userId/:assetSymbol/:edit',
              name: 'Admin Interest Rate Detail',
              props: true,
              meta: {
                useReturn: true
              },
              component: () => import('../views/admins/InterestRateView.vue')
            },
            {
              path: '/admin/market-position/:userId/:marketId/:edit',
              name: 'Admin Market Position Detail',
              props: true,
              meta: {
                useReturn: true
              },
              component: () => import('../views/admins/MarketPositionDetailView.vue')
            },
            {
              path: '/admin-market-edit/:baseAssetSymbol-:quoteAssetSymbol/:edit',
              name: 'admin-market-edit',
              props: true,
              meta: {
                useReturn: true
              },
              component: () => import('../views/admins/MarketDetailView.vue')
            },
            {
              path: '/admin/crypto-withdraw-request/:id',
              name: 'admin-crypto-withdraw-request-detail',
              props: true,
              meta: {
                noMenu: true,
                useReturn: true
              },
              component: () => import('../views/users/CryptoWithdrawRequestDetailView.vue')
            },
            {
              path: '/admin/crypto-deposit-request/:id',
              name: 'admin-crypto-deposit-request-detail',
              props: true,
              meta: {
                noMenu: true,
                useReturn: true
              },
              component: () => import('../views/users/CryptoDepositRequestDetailView.vue')
            },
            {
              path: '/admin/cash-deposit-request/:id',
              name: 'admin-cash-deposit-request-detail',
              props: true,
              meta: {
                noMenu: true,
                useReturn: true
              },
              component: () => import('../views/users/CashDepositRequestDetailView.vue')
            },
            {
              path: '/admin/cash-withdraw-request/:id',
              name: 'admin-cash-withdraw-request-detail',
              props: true,
              meta: {
                noMenu: true,
                useReturn: true
              },
              component: () => import('../views/users/CashWithdrawRequestDetailView.vue')
            },
            {
              path: '/admin/bank-withdraw-request/:id',
              name: 'admin-bank-withdraw-request-detail',
              props: true,
              meta: {
                noMenu: true,
                useReturn: true
              },
              component: () => import('../views/users/BankWithdrawRequestDetailView.vue')
            },
            {
              path: '/admin/transfer-request-request/:id',
              name: 'admin-transfer-request-detail',
              props: true,
              meta: {
                noMenu: true,
                useReturn: true
              },
              component: () => import('../views/users/TransferRequestDetail.vue')
            },
            {
              path: '/admin/order/:id',
              name: 'admin-order-detail',
              props: true,
              meta: {
                noMenu: true,
                useReturn: true
              },
              component: () => import('../views/users/OrderDetailView.vue')
            },
            {
              path: '/admin/message/:id',
              name: 'Admin Message Detail',
              props: true,
              meta: {
                noMenu: true,
                useReturn: true
              },
              component: () => import('../views/admins/MessageDetailView.vue')
            },
            {
              path: '/admin/actions/:nav',
              name: 'Admin Actions',
              default: '/admin/actions/brushing',
              props: true,
              component: () => import('../views/admins/ActionsView.vue')
            },
            {
              path: '/admin/network-transaction/:id',
              name: 'Admin Network Transaction Detail',
              props: true,
              meta: {
                useReturn: true
              },
              component: () => import('../views/admins/NetworkTransactionDetailView.vue')
            },
            {
              path: '/admin/reports/:nav',
              name: 'Admin Reports',
              default: '/admin/reports/balances',
              props: true,
              component: () => import('../views/admins/ReportsView.vue')
            },
            {
              path: '/admin/reports/admin-action/:id',
              name: 'admin-action-detail',
              props: true,
              meta: {
                useReturn: true
              },
              component: () => import('../views/admins/AdminActionDetailView.vue')
            },
            {
              path: '/admin/reports/report-detail/:id',
              name: 'report-detail',
              props: true,
              meta: {
                useReturn: true
              },
              component: () => import('../views/admins/ReportDetailView.vue')
            },
            {
              path: '/admin/reports/ipg-withdrawal/:id',
              name: 'ipg-withdrawal-detail',
              props: true,
              meta: {
                useReturn: true
              },
              component: () => import('../views/admins/IPGWithdrawalDetailView.vue')
            },
            {
              path: '/transfer-request/:id',
              name: 'transfer-request-detail',
              props: true,
              meta: {
                useReturn: true
              },
              component: () => import('../views/users/TransferRequestDetail.vue')
            },
            {
              path: '/admin/crypto-transaction/:id',
              name: 'Admin Crypto Transaction Detail',
              props: true,
              meta: {
                useReturn: true
              },
              component: () => import('../views/admins/CryptoTransactionDetailView.vue')
            },
            {
              path: '/admin/users',
              name: 'Admin Users',
              component: () => import('../views/admins/UsersView.vue')
            },
            {
              path: '/admin/user/:id/:nav',
              name: 'Admin User Detail',
              default: '/admin/user/:id/detail',
              props: true,
              component: () => import('../views/admins/UserView.vue')
            }
          ]
        },
      ]
    },
    {
      path: '/:catchAll(.*)', // Matches any route not defined above
      redirect: '/landing',
    }
  ]
})

export default router
